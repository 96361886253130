import React, { FC, useEffect, useState } from 'react'
import cx from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { userApiSelectors } from 'modules/api/user'
import { Switch, Button, PropertyInspectActivationModal, PropDataActivationModal } from 'views/components'
import { integrationsApiEvents, integrationsApiSelectors } from 'modules/api/integrations'
import TpnLoginModal from '../TpnCreditChecks/TpnLoginModal/TpnLoginModal'
import TpnEnquiryModal from '../TpnCreditChecks/TpnEnquiryModal/TpnEnquiryModal'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import Logo from '../../../../theme/images/logo.svg'
import styles from './IntegrationCardBody.module.scss'
import { apiStateSelectors } from 'modules/api/apiState'

type Props = {
  id: string
  available: boolean
  enabled: boolean
  logo: string
  name: string
  short: string
  orderNo: number
}

const IntegrationCardBody: FC<Props> = ({ available, enabled, id, logo, name, short }) => {
  const dispatch = useDispatch()
  const [propertyInspectActivationState, setPropertyInspectActivationState] = useState(false)
  const [propDataActivationState, setPropDataActivationState] = useState(false)
  const [syncExistingProperties, setSyncExistingProperties] = useState(false)
  const [isTpnCustomEnquiryModalOpen, setIsTpnCustomEnquiryModalOpen] = useState(false)
  const [isRefreshTpnConnectOpen, setIsRefreshTpnConnectOpen] = useState(false)

  const classes = cx(styles.body, {
    [styles['body--enabled']]: enabled,
    [styles['body--disabled']]: !enabled,
  })

  const isValidSupportStaff = useSelector(userApiSelectors.isValidSupportStaff)
  const isOwnerRole = useSelector(userApiSelectors.isOwnerRole)
  const isTeamMemberRole = useSelector(userApiSelectors.isTeamMemberRole)
  const isTpnConnectEnabled = name === 'TPN Connect' && enabled
  const getConsumerCanAccessCredex = useSelector(integrationsApiSelectors.getConsumerCanAccessCredex)
  const isPropDataRefreshLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([integrationsApiEvents.refreshPropDataIntegration_request]),
  )

  const canUserAccessCredex = Object.values(getConsumerCanAccessCredex)

  useEffect(() => {
    if (isTpnConnectEnabled) {
      dispatch(integrationsApiEvents.tpnConsumerCanAccessCredex_request())
    }
  }, [isTpnConnectEnabled])

  const handlePropertyInspectActivate = () => {
    dispatch(integrationsApiEvents.integrationOauthDetails_request({ syncExistingProperties }))
  }

  const handlePropertyInspectDeactivate = () => {
    dispatch(integrationsApiEvents.deactivateIntegration_request({ integrationType: 'PropertyInspect' }))
    setPropertyInspectActivationState(false)
  }

  const handlePropDataDeactivate = () => {
    dispatch(integrationsApiEvents.deactivateIntegration_request({ integrationType: 'PropData' }))
    setPropDataActivationState(false)
  }

  const handlePropDataRefresh = () => {
    dispatch(integrationsApiEvents.refreshPropDataToken_request())
  }

  return (
    <>
      <div className={classes}>
        <figure>
          <img className={styles.logo} src={logo ? logo : Logo} alt={name} />
        </figure>
        <div className={styles.content}>
          <h3>{name}</h3>
          <p>{short}</p>
        </div>
        {(isOwnerRole || isTeamMemberRole) && (
          <>
            <div className={styles.actions}>
              {available ? (
                <Switch
                  className="integration-toggle"
                  label={enabled ? 'Enabled' : 'Disabled'}
                  checked={enabled}
                  onChange={() => setPropertyInspectActivationState(true)}
                />
              ) : name === 'AutoEvict (BETA)' ? (
                <a href="https://autoevict.co.za/" target="_blank" rel="noopener noreferrer">
                  Learn more
                </a>
              ) : name === 'Prop Data' && isValidSupportStaff ? (
                <>
                  <Switch
                    className="integration-toggle"
                    label={enabled ? 'Enabled' : 'Disabled'}
                    checked={enabled}
                    onChange={() => setPropDataActivationState(true)}
                  />
                  {enabled && (
                    <div className={styles.refresh}>
                      <Button loading={isPropDataRefreshLoading} size="sm" onClick={handlePropDataRefresh}>
                        Refresh
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <p>Watch this space</p>
              )}
            </div>
          </>
        )}
      </div>
      {enabled && !canUserAccessCredex[0] ? (
        <TpnLoginModal isOpen={isRefreshTpnConnectOpen} onClose={() => setIsRefreshTpnConnectOpen(false)} />
      ) : name === 'TPN Connect' && enabled ? (
        <TpnEnquiryModal isOpen={isTpnCustomEnquiryModalOpen} onClose={() => setIsTpnCustomEnquiryModalOpen(false)} />
      ) : name === 'TPN Connect' && !enabled ? (
        <TpnLoginModal
          isOpen={propertyInspectActivationState}
          onClose={() => setPropertyInspectActivationState(false)}
        />
      ) : null}

      {name === 'Property Inspect' && enabled ? (
        <PropertyInspectActivationModal
          isOpen={propertyInspectActivationState}
          title={name}
          consent="Disable this integration?"
          enabled={enabled}
          syncExistingProperties={syncExistingProperties}
          onSkipInitialSyncChange={() => setSyncExistingProperties(!syncExistingProperties)}
          onActivate={handlePropertyInspectDeactivate}
          onCancel={() => setPropertyInspectActivationState(false)}
        />
      ) : name === 'Property Inspect' && !enabled ? (
        <PropertyInspectActivationModal
          isOpen={propertyInspectActivationState}
          title={name}
          consent="Enable this integration?"
          enabled={enabled}
          syncExistingProperties={syncExistingProperties}
          onSkipInitialSyncChange={() => setSyncExistingProperties(!syncExistingProperties)}
          onActivate={handlePropertyInspectActivate}
          onCancel={() => setPropertyInspectActivationState(false)}
        />
      ) : name === 'Prop Data' && enabled ? (
        <PropDataActivationModal
          title={name}
          consent="Disable this integration?"
          confirmation="Yes"
          isOpen={propDataActivationState}
          isActivated={enabled}
          onDeactivate={handlePropDataDeactivate}
          onCancel={() => setPropDataActivationState(false)}
        />
      ) : name === 'Prop Data' && !enabled ? (
        <PropDataActivationModal
          title={name}
          consent="Please fill out the following fields in order to enable this integration."
          confirmation="Enable"
          isOpen={propDataActivationState}
          isActivated={enabled}
          onCancel={() => setPropDataActivationState(false)}
        />
      ) : null}
    </>
  )
}

export default IntegrationCardBody
