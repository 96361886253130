import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { Formik } from 'formik'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { Modal, Button, TextField, FormField, TextInput } from '../../../../components'
import styles from './PropDataActivationModal.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { integrationsApiEvents } from 'modules/api/integrations'

const validationSchema = yup.object().shape({
  siteId: yup.string().required('is required'),
  branchId: yup.string().required('is required'),
})

type Props = {
  title?: string
  consent?: string
  confirmation: string
  isOpen: boolean
  isActivated: boolean
  onDeactivate?: () => void
  onCancel: () => void
}

const PropDataActivationModal = ({
  title,
  consent,
  confirmation,
  isOpen,
  isActivated,
  onDeactivate,
  onCancel,
}: Props) => {
  const dispatch = useDispatch()

  const _handleClose = useCallback(
    resetForm => {
      resetForm()
      onCancel()
    },
    [onCancel],
  )

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: $TSFixMe, { resetForm }) => {
        dispatch(
          integrationsApiEvents.activatePropDataIntegration_request({
            integrationType: 'PropData',
            siteId: values.siteId,
            branchId: values.branchId,
          }),
        )
        onCancel()
        resetForm()
      }}
      initialValues={{
        siteId: '',
        branchId: '',
      }}
    >
      {({ values, errors, handleSubmit, handleBlur, handleChange, resetForm }) => {
        return (
          <Modal className={styles.root} showClose={false} isOpen={isOpen}>
            <h3 className="Modal_Title">{title}</h3>
            <div className="Modal_Content">
              <p>{consent}</p>
              {!isActivated && (
                <>
                  <FormField>
                    <TextField
                      inputComponent={
                        <TextInput
                          type={TextFieldTypes.text}
                          id="siteId"
                          name="siteId"
                          placeholder="e.g. 11"
                          value={values.siteId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label="Site ID"
                      error={errors?.siteId}
                    />
                  </FormField>
                  <FormField>
                    <TextField
                      inputComponent={
                        <TextInput
                          type={TextFieldTypes.text}
                          id="branchId"
                          name="branchId"
                          placeholder="e.g. 1"
                          value={values.branchId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label="Branch ID"
                      error={errors?.branchId}
                    />
                  </FormField>
                </>
              )}
            </div>
            <div className="Modal_Actions">
              <Button link onClick={isActivated ? () => onDeactivate && onDeactivate() : () => handleSubmit()}>
                {confirmation}
              </Button>
              <Button link onClick={() => _handleClose(resetForm)}>
                Close
              </Button>
            </div>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default PropDataActivationModal
