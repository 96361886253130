import React from 'react'
import Button from 'components/atoms/Button/Button'
import Modal from 'components/molecules/Modal/Modal'
import Switch from 'views/components/atoms/Switch/Switch'
import styles from './PropertyInspectActivationModal.module.scss'

type Props = {
  title?: string
  consent?: string
  enabled?: boolean
  syncExistingProperties: boolean
  isOpen: boolean
  onSkipInitialSyncChange: (value: boolean) => void
  onActivate: () => void
  onCancel: () => void
}

const PropertyInspectActivationModal = ({
  title,
  consent,
  enabled,
  syncExistingProperties,
  isOpen,
  onSkipInitialSyncChange,
  onActivate,
  onCancel,
}: Props) => {
  return (
    <Modal className={styles.root} showClose={false} isOpen={isOpen}>
      <h3 className="Modal_Title">{title}</h3>
      <div className="Modal_Content">
        <p>{consent}</p>
        {!enabled && (
          <Switch
            label="Sync Existing Properties"
            checked={syncExistingProperties}
            onChange={onSkipInitialSyncChange}
          />
        )}
      </div>
      <div className="Modal_Actions">
        <Button link onClick={onActivate}>
          Yes
        </Button>
        <Button link onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default PropertyInspectActivationModal
